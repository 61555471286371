body,
html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

#main {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

#app {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#notif {
    display: flex;
    flex-direction: column;
    height: 50px;
    align-items: center;
}

#notif>* {
    display: block;
}

