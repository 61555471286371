.library .item{
    cursor: grab;
}
.item pre{
    user-select: none;

}

.library .item:hover{
    background-color: rgba(50,50,50,1)
}