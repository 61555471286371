body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#main {
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  display: flex;
}

#app {
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

#notif {
  height: 50px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

#notif > * {
  display: block;
}

.box-select {
  z-index: 99;
  background: rgba(123, 70, 166, .4);
  border: 2px solid rgba(123, 70, 166, .8);
  border-radius: 2px;
  display: none;
  position: absolute;
}

.box-select-container {
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}

.overlay-bottom {
  --overlay-size: 10%;
  --overlay-space-left: 10%;
}

.overlay-content, .overlay-trigger {
  position: fixed;
  bottom: 0;
}

.overlay-trigger {
  width: 100%;
  height: calc( 1.5 * var(--overlay-size));
  left: 0;
}

.overlay-content {
  width: calc( 100% - var(--overlay-space-left));
  left: var(--overlay-space-left);
  height: var(--overlay-size);
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  transition: bottom .2s;
  display: flex;
}

.overlay-bottom > .overlay-content {
  bottom: calc( -1 * var(--overlay-size));
}

.overlay-bottom.visible > .overlay-content {
  bottom: 0;
}

.context-menu {
  cursor: default;
  min-width: 150px;
  min-height: 50px;
  z-index: 10;
  color: #a9a9a9;
  background-color: #323232;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1em;
  position: fixed;
}

.menu-context {
  height: 10px;
  font-size: .6em;
}

.menu-context .clickable {
  cursor: pointer;
  opacity: .8;
}

.menu-context .clickable:hover {
  cursor: pointer;
  opacity: 1;
}

.context-menu {
  transition: width 1s ease-out;
}

.menu-title {
  border-bottom: 1px solid #fff;
  justify-content: center;
  margin-top: 0;
  padding: 4px 10px 8px;
  display: flex;
}

.action-list {
  flex-direction: column;
  margin: 0;
  display: flex;
}

.action-list > div {
  cursor: pointer;
  padding: 10px 20px;
}

.action-list > div:hover {
  background-color: #161616;
}

.library .item {
  cursor: grab;
}

.item pre {
  -webkit-user-select: none;
  user-select: none;
}

.library .item:hover {
  background-color: #323232;
}

body {
  overflow: hidden;
}

header > .row {
  flex-wrap: wrap;
}

body {
  height: 100vh;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
}

.notifications-container {
  width: 100%;
  z-index: 100;
  position: fixed;
  top: 50px;
  left: 0;
}

.notifications {
  width: 100%;
  flex-direction: column-reverse;
  align-items: center;
  display: flex;
  top: 0;
}

.notification {
  height: 1px;
  color: #d3d3d3;
  width: 100%;
  z-index: 20;
  max-width: 80%;
  -webkit-user-select: none;
  user-select: none;
  background-color: #222;
  border: 1px solid #fff;
  border-radius: 3px;
  justify-content: flex-start;
  align-items: center;
  margin: 2px;
  padding: 0 5px 0 10px;
  animation: .1s forwards growingNotification;
  display: flex;
  overflow: hidden;
}

@keyframes growingNotification {
  to {
    height: 100%;
    min-height: 40px;
  }
}

.notification {
  font-size: unset !important;
}

.notification.error {
  background-color: #8b0000;
}

.notification.warning {
  background-color: #ff8c00;
}

.notification.info {
  background-color: #00008b;
}

.notification.success {
  color: #000;
  background-color: #32cd32;
  border-color: #000;
}

.notification.debug {
  background-color: #696969;
}

@media (min-width: 1200px), screen and (orientation: landscape) {
  .notification {
    max-width: 50%;
  }
}

.row {
  justify-content: stretch;
  align-items: center;
  display: flex;
}

.row button, .row select {
  align-self: stretch;
}

button svg {
  width: 20px;
  height: 20px;
}

button {
  cursor: pointer;
}

button:disabled {
  opacity: .4;
  cursor: not-allowed;
}

#widget-gsi div {
  justify-content: center;
  align-items: center;
  display: flex;
}

/*# sourceMappingURL=index.c8c1dcf6.css.map */
